
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormTextarea from "@/components/form/Textarea.vue";
import PCalendar from "primevue/calendar";

import { dayOfWeek, addHoursMask, formatDateEn } from "@/libs/utils";
import { AgendaLockForm } from "@/store/agenda/types";

const VLockForm = defineComponent({
  name: "VLockForm",
  components: { CModal, CFormInputText, CFormTextarea, PCalendar },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const _id = Number(route.params._id);
    const isEditForm = route.name?.toString().includes("-edit");
    const lockID = Number(route.query.id);
    const date = String(route.query.date);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} bloqueio | obmed` });

    const lockDays = computed(() => store.state.agenda.lockDays);
    const lockDay = computed(() => lockDays.value.find((item) => item.id === lockID));

    const form = reactive<AgendaLockForm>({
      cd_agenda: _id,
      ds_motivo_bloqueio: "",
      ds_observacao_bloqueio: "",
      dt_fim_bloqueio: "",
      dt_inicio_bloqueio: "",
      hr_fim: "",
      hr_inicio: "",
    });
    const loading = reactive({ submit: false, deletion: false });
    const confirmDeletion = ref(false);

    function handleClose() {
      router.back();
    }

    async function handleDeletion() {
      if (confirmDeletion.value === false) {
        confirmDeletion.value = true;
        setTimeout(() => {
          confirmDeletion.value = false;
        }, 3000);

        return;
      }

      confirmDeletion.value = false;
      loading.deletion = true;
      const response = await store.dispatch("deleteAgendaLockDay", { _id: lockID });
      loading.deletion = false;

      if (response?.status === 204) {
        store.dispatch("getAgendaLockDays", { _id });
        handleClose();
      }
    }

    async function onSubmit() {
      loading.submit = true;

      const response = isEditForm
        ? await store.dispatch("editAgendaLockDay", {
            _id: lockID,
            form: {
              ...form,
              dt_inicio_bloqueio: formatDateEn(String(form.dt_inicio_bloqueio)),
              dt_fim_bloqueio: formatDateEn(String(form.dt_fim_bloqueio)),
              hr_inicio: undefined,
              hr_fim: undefined,
            },
          })
        : await store.dispatch("addAgendaLockDay", {
            form: {
              ...form,
              dt_inicio_bloqueio: formatDateEn(String(form.dt_inicio_bloqueio)),
              dt_fim_bloqueio: formatDateEn(String(form.dt_fim_bloqueio)),
            },
          });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getAgendaLockDays", { _id });
        handleClose();
      }
    }

    function applyForm() {
      form.dt_fim_bloqueio = lockDay.value?.dt_fim_bloqueio
        ? new Date(lockDay.value.dt_fim_bloqueio.replaceAll("-", "/"))
        : "";
      form.dt_inicio_bloqueio = lockDay.value?.dt_inicio_bloqueio
        ? new Date(lockDay.value.dt_inicio_bloqueio.replaceAll("-", "/"))
        : date !== "undefined"
        ? new Date(date.includes("T") ? date : date.replaceAll("-", "/"))
        : new Date();
      form.ds_motivo_bloqueio = lockDay.value?.ds_motivo_bloqueio || "";
      form.ds_observacao_bloqueio = lockDay.value?.ds_observacao_bloqueio || "";
    }

    applyForm();
    watch(lockDay, applyForm);

    return {
      form,
      loading,
      dayOfWeek,
      isEditForm,
      confirmDeletion,
      handleClose,
      handleDeletion,
      onSubmit,
      addHoursMask,
      formatDateEn,
    };
  },
});

export default VLockForm;
